<template>
  <div>
    <div v-if="! user.id" class="mt-12 max-w-md mx-auto flex justify-center">
      <loading-icon class="h-10 w-10 text-pink-500"/>
    </div>
    <div v-else class="mt-12 max-w-3xl w-full">
      <div class="mt-12 grid grid-cols-2 md:grid-cols-3 gap-8">
        <div v-for="show in shows" :key="`show-${show.id}`" class="relative pb-full">
          <router-link :to="{ name: 'ShowDetails', params: { showId: show.id } }" class="absolute inset-0 p-4 rounded-xl border-2 border-gray-50 bg-white shadow-xl cursor-pointer hover:border-pink-500 hover:bg-pink-100">
            <div class="relative rounded-xl overflow-hidden w-full h-full">
              <template v-if="show.imageUrl">
                <img :src="show.imageUrl" class="w-full h-full object-cover" alt="">
              </template>
              <template v-else>
                <img src="/img/test_image.01d18838.png" class="w-full h-full object-cover" alt="">
                <span class="absolute inset-x-0 bottom-0 h-10 flex items-center justify-center bg-black bg-opacity-75 text-gray-200">
                  {{ show.name }}
                </span>
              </template>
            </div>
          </router-link>
        </div>
        <div class="relative pb-full">
          <div class="absolute inset-0 p-4 rounded-xl border-2 border-dashed border-pink-500 bg-white shadow-xl cursor-pointer hover:bg-pink-100 bg-gray-50">
            <router-link :to="{ name: 'ShowCreate' }" class="w-full h-full flex flex-col items-center justify-center">
              <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="plus-circle w-10 h-10"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              <span class="mt-4 text-gray-600 text-center">Create <br> show</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        //
      }
    },

    computed: {
      user() {
        return this.$store.getters['auth/getUser']
      },

      shows() {
        return ! this.user.id ? [] : this.user.shows
      },
    },

    mounted() {
      this.$store.dispatch('auth/getUser')
    }
  }
</script>
